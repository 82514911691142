.contactContainer {
  background-color: #1e1e1e;
  max-width: 510px;
  flex-direction: column;
  align-items: start;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 125%;
  padding: 44px 50px;
  margin: 0 auto;
  top: 0;
}

@media (max-width: 991px) {
  .contactContainer {
    padding: 20px 20px;
  }
}

.formMain {
  width: 100%;
}

.formRoot{
  opacity: 0; /* Start with opacity 0 */
  transition: opacity 0.3s ease-in-out; /* Define the transition property */
  transition: transform 0.3s ease-in-out;
  transform: translateY(10%);
}

.formActive{
  transform: translateY(0);
  opacity: 1;
}

.headerContainer {
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: -3px;
  line-height: 94%;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .headerContainer {
    max-width: 100%;
    flex-wrap: wrap;
    font-size: 40px;
  }
}

.headerText {
  font-family: Basic, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font-weight: 500;
}

@media (max-width: 991px) {
  .headerText {
    font-size: 30px;
  }
}

.headerIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 60px;
}

.contactOptions {
  display: flex;
  margin-top: 40px;
  gap: 17px;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 991px) {
  .contactOptions {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
    margin-top: 40px;
    white-space: initial;
  }
}

.activeOption {
  color: #fff;
  border: 2px solid rgba(208, 208, 208, 1);
}

.contactOption {
  font-family: Lato, sans-serif;
  font-size: 20px ;
  border-radius: 5px;
  padding: 25px 22px 15px;
  flex: 1;
  color: #D0D0D0;
  border: 2px solid #D0D0D0;
}

@media (max-width: 991px) {
  .contactOption {
    white-space: initial;
    padding: 0 20px;
  }
}

.phoneNumber {
  font-family: Lato, sans-serif;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 35px;
  width: 100%;
  color: #1e1e1e;
  font-weight: 600;
  text-align: center;
  padding: 12px 60px;
}

@media (max-width: 991px) {
  .phoneNumber {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.divider {
  background-color: #a1a1a1;
  margin-top: 35px;
  width: 100%;
  height: 1px;
  border: 1px solid rgba(161, 161, 161, 1);
}

@media (max-width: 991px) {
  .divider {
    margin-top: 40px;
  }
}

.formRow {
  display: flex;
  margin-top: 35px;
  gap: 20px;
}

@media (max-width: 991px) {
  .formRow {
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  width: 100%;
}

.formLabel {
  font-family: Basic, sans-serif;
}

.formInput {
  color: #f6f6f6;
  padding-bottom: 10px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #F6F6F6;
  background: transparent;
  width: 100%;
}

.formInput:focus-visible{
  outline: none;
}

.formInput::placeholder {
  color: #f6f6f6;
}

.dropdownContainer {
  display: flex;
  margin-top: 25px;
  gap: 20px;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .dropdownContainer {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
    margin-top: 40px;
    white-space: initial;
  }
}

.dropdownLabel {
  font-family: Basic, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}

.pointer{
  cursor: pointer;
}

.dropdownIcon {
  aspect-ratio: 1.82;
  object-fit: auto;
  object-position: center;
  width: 11px;
  fill: #fff;
  margin: auto 0;
}

.messageLabel {
  font-family: Basic, sans-serif;
  margin-top: 45px;
}

@media (max-width: 991px) {
  .messageLabel {
    max-width: 100%;
    margin-top: 40px;
  }
}

.submitContainer {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  line-height: 158%;
  align-items: center;
}

@media (max-width: 991px) {
  .submitContainer {
    margin-left: 9px;
    white-space: initial;
  }
}

.submitButton {
  font-family: Lato, sans-serif;
  margin: auto 0;
}

.submitIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 65px;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.customSelect {
  position: relative;
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid #F6F6F6;
  background: transparent;
  color:#F6F6F6;
}

.customSelect:focus-visible{
  outline: none;
}

.msgInput {
  color: #f6f6f6;
  padding-bottom: 10px;
  margin-top: 25px;
  height: 40px;
  width: 100%;
}
