.container {
  padding-top:25px;
  --card-gap: 1rem;
  --card-padding: 1rem;
  --text-color: #ffff;
  --background-color: #fff;
  --transition-duration: 0.3s;
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  padding: var(--card-padding);
  background-color: var(--background-color);
}

.card {
  background-color:#fff;
  padding: 20px;
  margin:20px;
  position: relative;
  overflow: hidden;
  padding:20px;
  aspect-ratio: 3 / 4;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: transform var(--transition-duration) ease;
}

.card:hover, .card.hovered {
  transform: translateY(-10px);
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7);
  transition: filter var(--transition-duration) ease;
}

.card:hover .image, .card.hovered .image {
  filter: brightness(0.4);
}

.info {
  font-style: 'Basic', sans-serif;

  position: absolute;
  bottom: var(--card-padding);
  right: var(--card-padding);
  color: var(--text-color);
  text-align: right;
  transition: transform var(--transition-duration) ease;
}

.card:hover .info, .card.hovered .info {
  transform: translateY(-2rem);
}

.name {
  font-style: 'Basic', sans-serif;

  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.title {
  font-style: 'Lato', sans-serif;

  font-size: 0.75rem;
  margin: 0.25rem 0 0;
}

.bio {
  font-size: 0.875rem;
  margin: 0.5rem 0 0;
  max-width: 250px;
  opacity: 0;
  transition: opacity var(--transition-duration) ease;
}

.card:hover .bio, .card.hovered .bio {
  opacity: 1;
}

.arrow {
  position: absolute;
  top: var(--card-padding);
  right: var(--card-padding);
  color: var(--text-color);
  transition: transform var(--transition-duration) ease;
}

.card:hover .arrow, .card.hovered .arrow {
  transform: rotate(45deg);
}

@media (min-width: 640px) {
  .container {
    --card-gap: 1.5rem;
    --card-padding: 1.5rem;
  }

  .name {
    font-size: 2rem;
  }

  .title {
    font-size: 0.875rem;
  }

  .bio {
    font-size: 1rem;
  }
}