/* ProductImage.module.css */
.imageContainer {
  position: relative;
  width: 50%;
}

.backLink {
  position: absolute;
  top: 1rem;
  left: .5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #121212;
  z-index: 10;
  transition: opacity 0.3s ease;
}

.backLink:hover {
  opacity: 0.4;
}

.backArrow {
  width: 75px;
  height: 75px;
  margin-right: 0.5rem;
}

.backText {
  font-size: 0.9rem;
  font-weight: bold;
}

.mainImage {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease-in-out;
}

.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.thumbnail {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #555;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.thumbnail.active {
  background-color: #fff;
}

.thumbnail:hover {
  background-color: #888;
}

.navigation {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.navigation button {
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.navigation button:hover {
  opacity: 0.8;
}