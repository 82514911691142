.resourcesList {
  width: 100%;
}

.divider {
  background-color: #646464;
  height: 1px;
  border: none;
  margin: 30px 0;
}

@media (max-width: 991px) {
  .resourcesContainer {
    padding: 40px 20px;
  }
}

/* .resourcesContainerOne{
  padding: 30px 50px;
  width: 100%;
} */
