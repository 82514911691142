/**
 * This code was generated by Builder.io.
 */
.heroSection {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  align-items: center;
  color: #fff;
  justify-content: center;
  padding: 80px 60px;
}

@media (max-width: 991px) {
  .heroSection {
    padding: 0 20px;
  }
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.contentWrapper {
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 1601px;
  flex-direction: column;
  bottom: 0;
  padding: 0 60px;
  padding-bottom: 60px;
}

@media (max-width: 991px) {
  .contentWrapper {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 30px;
  }
}

.headline {
  letter-spacing: -3.6px;
  font: 400 6rem Basic, sans-serif;
  max-width: 1000px;
  line-height: 102px;
}

@media (max-width: 991px) {
  .headline {
    max-width: 100%;
    font-size: 50px;
    line-height: 1;
  }
}

.contentRow {
  display: flex;
  width: 100%;
  gap: 20px;
}

@media (max-width: 991px) {
  .contentRow {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.description {
  align-self: start;
  margin-top: 13px;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 18px/28px Lato, sans-serif;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
  }
}

.ctaWrapper {
  display: flex;
  gap: 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.ctaText {
  font-family: Lato, sans-serif;
  margin: auto 0;
}

.ctaIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 89px;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.divider {
  margin-top: 21px;
  height: 1px;
  border: 1px solid rgba(100, 100, 100, 1);
}
