.container {
  background-color: #121212;
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 37px 0 66px;
  height: 100vh; /* Ensure full viewport height */

}

.logo {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 54px;
  align-self: flex-end;
  margin-right: 31px;
}

.menuContainer {
  display: flex;
  margin-top: 55px;
  width: 100%;
  flex-direction: column;
  padding: 0 50px;
  height: 100vh;
}

.menuItem {
  color: #fff;
  letter-spacing: -2px;
  text-transform: capitalize;
  font: 400 50px/115% Basic, -apple-system, Roboto, Helvetica, sans-serif;
}

.menuItem:hover{
  text-decoration: underline;
}


.menuItem:not(:first-child) {
  margin-top: 12px;
}

.subMenu {
  color: #fff;
  text-transform: capitalize;
  margin-top: 55px;
  font: 400 18px/56px Lato, sans-serif;
  display: flex;
  flex-direction: column;
  line-height: 30px;
}

.subMenu a:hover{
  text-decoration: underline;
}

.divider {
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 56px;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.socialIcons {
  display: flex;
  margin-top: 30px;
  padding-right: 39px;
  gap: 20px;
  justify-content: space-between;
}

.socialIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 45px;
}
