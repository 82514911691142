.faqAccordion {
  margin-top: 10px;
  margin-left: -30px;
  width: 100%;
}

.faqTitle {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Basic", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 7px 0;

  transition: color 0.3s ease;
}



.faqTitle img {
  margin-right: 10px;
  transition: transform 0.3s ease;
  height: 25px;
  width: 25px;
}

.faqContent {
  color: #fff;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
  font-family: "Lato", Helvetica;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 40px;
}

.faqContent p {
  padding-bottom: 10px;
}

.faqContent.active {
  max-height: 200px;
  opacity: 1;
  transform: translateY(0);
}


@media (max-width: 991px) {
  .faqContent {
    color: #000;
  }
}

.faqDivider {
  height: 1px;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;
}
