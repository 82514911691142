.resourcesContainer {
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  max-width: 1440px;
  margin: 0 auto;
}

.resourcesList {
  width: 100%;
}

.divider {
  background-color: #646464;
  height: 1px;
  border: none;
  margin: 30px 0;
}

@media (max-width: 991px) {
  .resourcesContainer {
    padding: 40px 20px;
  }
}

.resourcesContainerOne{
  padding: 10px 10px;
  width: 100%;
}
