
.form {
    max-width: 800px;
    /* margin: 0 auto; */
    font-family: 'Basic', sans-serif;
    padding: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  
  .column {
    width: 48%;
  }
  
  .fullWidth {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .input,
  .select,
  .textarea {
    width: 100%;
    padding: 10px 0;
    border: none;
    background-color: transparent;
    font-size: 16px;
  }
  
  .input::placeholder,
  .select::placeholder,
  .textarea::placeholder {
    opacity: 1;
  }
  
  .select {
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23999" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  
  .textarea {
    height: 25x;
    resize: vertical;
  }
  
  .line {
    height: 2px;
    margin-top: 5px;
  }
  
  .submitButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .submitText {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }

  .submitArrow {
margin-top:10px;
    width: 89px;
    height: 89px;
    background-image: url('/icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease;
  }
  
  .submitButton:hover .submitArrow {
    background-image: url('/icon.svg');
  }
  
  /* .submitArrow {
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .submitButton:hover .submitArrow {
    background-color: currentColor;
  } */
  
  .submitButton:hover .submitArrow img {
    filter: invert(1);
  }
  
  /* Light Mode */
  .lightMode {
    background-color: white;
    color: black;
  }
  
  .lightMode .line {
    background-color: black;
  }
  
  .lightMode .input::placeholder,
  .lightMode .select::placeholder,
  .lightMode .textarea::placeholder {
    color: black;
  }
  
  /* Dark Mode */
  .darkMode {
    background-color: #121212;
    color: white;
  }
  
  .darkMode .line {
    background-color: white;
  }
  
  .darkMode .input::placeholder,
  .darkMode .select::placeholder,
  .darkMode .textarea::placeholder {
    color: white;
  }
  
  /* .darkMode .submitArrow {
    border-color: white;
  }
   */
  /* .darkMode .submitButton:hover .submitArrow {
    background-color: white;
  } */

  .errorMessage {
    color: #ff4444;
    margin: 10px 0;
    text-align: center;
    font-size: 14px;
  }
  
  .submitButtonDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


  .errorMessage {
    color: #ff4444;
    margin: 10px 0;
    text-align: center;
    font-size: 14px;
  }
  
  .submitButtonDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Add these to your existing modal styles if they don't exist */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .modalContent button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modalContent button:hover {
    background-color: #0056b3;
  }


  
  .darkMode .submitArrow {
    margin-top:10px;
       width: 89px;
       height: 89px;
       background-image: url('/icon_dark.svg') !important;
       background-size: cover;
       background-position: center;
       background-repeat: no-repeat;
       transition: all 0.3s ease;
     }
     
   .darkMode .submitButton:hover .submitArrow {
       background-image: url('/icon_dark.svg') !important;
     }
  .darkMode .submitButton:hover .submitArrow img {
    filter: invert(0);

    
  }

  
/* .form {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .input,
  .select,
  .textarea {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 5px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #333;
  }
  
  .input::placeholder,
  .select::placeholder,
  .textarea::placeholder {
    color: #999;
  }
  
  .select {
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23999" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  
  .textarea {
    height: 100px;
    resize: vertical;
  }
  
  .line {
    height: 1px;
    background-color: #ccc;
    margin-bottom: 15px;
  }
  
  .submitButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .submitText {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .submitArrow {
    width: 40px;
    height: 40px;
    border: 1px solid #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .submitButton:hover .submitArrow {
    background-color: #000;
  }
  
  .submitButton:hover .submitArrow img {
    filter: invert(1);
  }




/* .form {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .submitButton {
    height: 89px;
    width: 179px;
    position: relative;
    cursor: pointer;
  }
  
  .submitArrow {
    height: 32px;
    width: 32px;
    position: absolute;
    left: 28px;
    top: 28px;
  }
  
  .submitText {
    color: #ffffff;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2.8px;
    line-height: 22.1px;
    position: absolute;
    left: 0;
    top: 36px;
    white-space: nowrap;
  }
  
  .input {
    background: transparent;
    border: none;
    color: #ffffff;
    font-family: "Basic", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.5px;
    padding: 0;
    width: 335px;
    background-color: inherit;
    margin-bottom: 30px;
  }
  
  .input:hover,
  .input:focus {
    text-decoration: none !important;
    outline: none;
  }
  
  .select {
    composes: input;
    appearance: none;
    background-image: url('/img/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
  }
  
  .textarea {
    composes: input;
    resize: vertical;
    min-height: 100px;
  }
  
  .line {
    height: 1px;
    width: 335px;
    background-color: #ffffff;
    margin-bottom: 30px;
  } */ 