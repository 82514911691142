.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.25rem;
    /* padding: 1.5rem; */
    background-color: #ffffff;
  }

  .basic-regular {
    font-family: "Basic", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .product {
    background-color: rgba(238,238,238, 0.6);
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .imageContainer {
    width: 12rem;
    height: 12rem;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .productName {
    color: #121212;
    font-family: "Basic", sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    /* margin-bottom: 0.5rem; */
  }

  .productSub {
    color: #121212;
    font-family: "Basic", sans-serif;
    font-size: 1.0rem;
    /* font-weight: bold; */
    /* margin-bottom: 0.5rem; */
  }
  
  .productPrice {
    color:#888888;
    font-family: "Basic", sans-serif;
    font-size: 1.2rem;
    /* font-weight: bold; */
    margin-bottom: 1rem;
  }
  
  .addToCartButton {
    width: 100%;
    background-color: black;
    color: white;
  }
  
  .addToCartButton:hover {
    background-color: #1f2937;
  }
  
  @media (min-width: 640px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }