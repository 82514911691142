.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    /* padding: 2rem; */
  }
  
  .widthControl {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .widthControl label {
    margin-bottom: 0.5rem;
  }
  
  .widthControl input {
    width: 300px;
  }
  
  .productGridContainer {
    width: 100%;
    max-width: 800px;
  }