.buttonContainer {
    position: fixed;
    bottom: 1.5rem !important;
    right: 1rem;
    z-index: 999999999999999 !important;
  }
  
  .openButton {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0;
    border-radius: 50%;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .buttonIcon {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 60;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .modalContent {
    background-color: #121212;
    color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 90%;
    max-width: 400px;
    position: relative;
    animation: slideIn 0.3s ease-out;
  }
  
  @keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .closeButton {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .closeIcon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .modalTitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .tabContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .tab {
    flex-grow: 1;
    background: none;
    border: 1px solid white;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.875rem;
  }
  
  .tab:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .tab:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .activeTab {
    background-color: white;
    color: #121212;
  }
  
  .phoneContainer {
    background-color: white;
    color: #121212;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }
  
  .phoneNumber {
    text-align: center;
    font-weight: bold;
    font-size: 0.875rem;
  }
  
  .form {
    display: grid;
    gap: 1rem;
  }
  
  .input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    font-size: 0.875rem;
    padding: 0.5rem 0;
  }
  
  .input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .input:focus {
    outline: none;
    border-color: white;
  }
  
  .submitButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    margin-top: 1rem;
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .submitButton:hover {
    opacity: 0.8;
  }
  
  .submitIcon {
    width: 3rem;
    height: 3rem;
  }