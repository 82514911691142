.instRoot {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: left;
    /* padding: 50px; */
    z-index: 1;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .container {
    max-width: 800px;
    flex-direction: column;
  }
  
  .resourceTitle {
    letter-spacing: -3.4px;
    text-transform: capitalize;
    font: 400 60px/94% 'Basic', sans-serif;
    margin-bottom: 20px;
  }
  
  .resourceDescription {
    font: 400 16px/28px 'Lato', sans-serif;
    margin-bottom: 20px;
  }
  
  /* .themeToggle {
    margin-bottom: 20px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
   */
  /* .themeToggle:hover {
    background-color: #666666;
  } */
  
  .faqDivider {
    margin: 20px 0;
  }
  
  @media (max-width: 991px) {
    .resourceTitle {
      font-size: 40px;
      letter-spacing: -1.4px;
    }
  }