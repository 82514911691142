/* ProductPage.module.css */
.container {
    background-color: #fff;
    color: #fff;
    font-family: 'Lato', sans-serif;
  }
  
  .main {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 2.5rem;

    padding: 2rem;
    
    padding-bottom:5rem;
  }
  
  .productSection {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    background-color:#fff;
  }
  
  @media (max-width: 768px) {
    .productSection {
      flex-direction: column;
    }
  }
  
  /* Add more global styles as needed */