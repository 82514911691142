.resourceHeader{
  text-align: left;
  margin-bottom: 30px;
  max-width: 520px;
}



.tableContainer {
  height: 200px; /* Adjust this value to fit 5 rows */
  overflow-y: auto;
}

.tableRoot {
  width: 100%;
}



.resourceType{
  color: #FFF;
  text-transform: capitalize;
  font: 400 14px/125% Lato, sans-serif;
  margin-bottom: 20px;
}

.resourceTitle{
  color: #FFF;
  text-transform: capitalize;
  font: 400 60px/94% Basic, sans-serif;
  margin-bottom: 20px;
  line-height: 45px;
  font-size: 2.5em !important;
}

.title{
  font-size: 23px;
  color: #FFF;
}
.resourceDescription{
  color: #FFF;
  font: 400 12px/28px Lato, sans-serif;
}

.sopRoot{
  display: flex;
  justify-content: space-around;
  background: #181818;
  padding: 50px;
  flex-wrap: wrap;
  min-height: 660px;
}

@media (max-width: 550px) {
  .sopRoot{
    margin-top: 30px;
  }
  .resourceTitle{
    font-size: 40px;
  }
  .restartBtn{
    text-align: center;
  }
}

.instRoot{
  width: 100%;
  max-width: 540px;
  background-color: transparent;
  border: 1px solid #FFF;
}

.cardTitle{
  color: #FFF;
}

.restartBtn{
  margin-top: 50px;
}
.restartBtn button{
  background-color: #FFF;
  color: #181818;
  width: 250px;
}

.restartBtn button:hover{
  background-color: #FFF;
  color: #181818;
}
.radioRoot{
  display: flex;
  flex-direction: row;
  gap: 20px
}

.contentRoot{
  display: flex;
  flex-direction: column;
  gap: 20px
}

.subTitle{
  font-size: 18px;
  font: 400 14px/125% Lato, sans-serif;
  margin-bottom: 10px;
  color: #FFF;
}

.subMainTitle{
  font-size: 20px;
  font: 600 16px/125% Lato, sans-serif;
  margin-bottom: 10px;
  color: #FFF;
}

.boxCustom{
  border: 1px solid #FFF;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #FFF;
  font-family: Lato, sans-serif;
}

.activeBox{
  background: #FFF;
  color: black;
}

.tableRoot{
  color: #FFF;
  background: #202020 !important;
  margin-bottom: 30px;
  max-height: 200px;
  overflow: scroll;
}

.tableRow{
  border: none;
}
.shadInput{
  border: none;
  border-bottom: 1px solid #FFF;
  border-radius: 0;
  color: #FFF;
}
.shadInput::placeholder{
  color: #FFF;
}

.tableCell {
  cursor: pointer;
}
.tableCell:hover {
  background-color: hsl(var(--muted) / 0.5);
}

.tableCellActive{
  background-color: hsl(var(--muted) / 0.5);
}

.labelColor{
  color: #FFF;
}
