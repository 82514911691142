/* SolutionWorks.module.css */
.solutionWorks {
  font-family: 'Basic', sans-serif;
  font-size: 85px;
  font-style: normal;
  font-weight: 400;
  line-height: 94.023%; /* 79.919px */
  letter-spacing: -.5px;
  text-transform: capitalize;    
    color: #000;
    padding: 4rem 0rem;
  }
  
  .container {
    margin-top: 12rem !important;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    gap: 4rem;
  }
  
  .imageColumn {
    flex: 1;
  }
  
  .contentColumn {
    flex: 1;
  }
  
  .subtitle {
    font-size: 1rem;
    color: #0b0b0b;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 1rem;
    font-family: 'Lato', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 125.023%; /* 22.504px */
text-transform: capitalize;
  }
  
  .imageWrapper {
    position: relative;
    width: 100%;
    height: 600px;
  }
  
  .title {
    margin-top: -125px;
    margin-left: -70px;
    font-family: 'Basic', sans-serif;
    font-size: 110px;
    font-style: normal;
    font-weight: 400;
    line-height: 94.023%; /* 79.919px */
    letter-spacing: -.1px;
    text-transform: capitalize;   
    font-size: 3rem;
    /* font-weight: bold; */
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .description {
    padding-top:1rem;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;

    color: #0B0B0B;
    text-transform: none;   

font-family: 'Lato', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 173.023%; /* 27.684px */
  }
  
  .bulletPoints {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .bulletPoints li {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .imageWrapper {
      height: 400px;
      margin-bottom: 2rem;
    }
  
    .title {
      font-size: 2rem;
    }
  }