/* SystemDetails.module.css */
.systemDetails {
  background-color: #111 !important;
  color: #fff;
  padding: 2rem 8rem;
  width: 100%;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.subtitle {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  color: #888;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 5rem;
  line-height: 1.2;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 5rem;
}

.featureItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.featureHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.featureIconWrapper {
  width: 24px;
  height: 24px;
  margin-right: 25px;
  flex-shrink: 0;
}

.featureIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: bold;
}

.featureDescription {
  font-size: 0.9rem;
  color: #888;
  line-height: 1.6;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  margin-top: -128px;
  /* margin: 4rem auto; */
}

@media (max-width: 1024px) {
  .featuresGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .featuresGrid {
    grid-template-columns: 1fr;
  }

  .imageContainer {
    height: 300px;
  }
}