.resourceHeader {
  text-align: left;
  margin-bottom: 30px;
  max-width: 550px;
}

.container{
  color:#fff;
  max-width: 800px;
  flex-direction: column;
  padding: 30px 0;
}

.instRoot{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  padding-left: 50px;
  z-index: 1;
}

.darkBg{
  /* background-color: rgb(18, 18, 18); */
    /* background-color: rgb(255,255,255); */

}



.resourceType {
  color: #fff;
  text-transform: capitalize;
  font: 400 14px/125% 'Lato', sans-serif;
  margin-bottom: 20px;
}

.resourceTitle {
  color: #fff;
  letter-spacing: -3.4px;
  text-transform: capitalize;
  font: 400 60px/94% 'Basic', sans-serif;
  margin-bottom: 20px;
}

.resourceDescription {
  color: #fff;
  font: 400 16px/28px 'Lato', sans-serif;
}

@media (max-width: 991px) {
  .resourceTitle {
    font-size: 40px;
    letter-spacing: -1.4px;
  }
}

.whiteText{
  color: #ffffff !important;
}







/* 


.resourceHeader {
  text-align: left;
  margin-bottom: 30px;
  max-width: 550px;
}

.container{
  max-width: 800px;
  flex-direction: column;
  padding: 30px 0;
}

.instRoot{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  padding-left: 50px;
  z-index: 1;
}

.darkBg{
  background-color: rgb(18, 18, 18);
}



.resourceType {
  color: #121212;
  text-transform: capitalize;
  font: 400 14px/125% Lato, sans-serif;
  margin-bottom: 20px;
}

.resourceTitle {
  color: #121212;
  letter-spacing: -3.4px;
  text-transform: capitalize;
  font: 400 60px/94% Basic, sans-serif;
  margin-bottom: 20px;
}

.resourceDescription {
  color: #0b0b0b;
  font: 400 16px/28px Lato, sans-serif;
}

@media (max-width: 991px) {
  .resourceTitle {
    font-size: 40px;
    letter-spacing: -1.4px;
  }
}

.whiteText{
  color: #ffffff !important;
}
 */
