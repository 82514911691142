.footer {
  display: flex;
  margin-top: 73px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 0 4rem;
  background-color: rgba(19, 19, 19) ;
}

@media (max-width: 991px) {
  .footer {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 1rem;
  }
}

.footerWrapper {
  max-width: 1440px;
}

.navigationLinks {
  align-self: flex-end;
  display: flex;
  margin-right: 53px;
  width: 717px;
  max-width: 100%;
  gap: 20px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  line-height: 158%;
}

@media (max-width: 991px) {
  .navigationLinks {
    margin-right: 10px;
    flex-wrap: wrap;
  }
}

.navLink {
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  margin-bottom: 50px;
  color: #fff;
}

.contentWrapper {
  display: flex;
  margin-top: 72px;
  width: 100%;
  gap: 120px;
}

@media (max-width: 991px) {
  .contentWrapper {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 40px;
  }
  .navLink{
    margin: 0;
  }
}

.logoSection {
  flex-grow: 1;
  flex-basis: auto;
  margin-top: 70px;
}

@media (max-width: 991px) {
  .logoSection {
    max-width: 100%;
  }
}

.logoContent {
  gap: 20px;
  display: flex;
  align-items: start;
}

@media (max-width: 991px) {
  .logoContent {
    align-items: stretch;
    gap: 0;
  }
}

.logoColumn {
  display: flex;
  line-height: normal;
  width: 23%;
  margin-left: 0;
}

.logo {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 70px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .logo {
    margin-top: 36px;
  }
}

.descriptionColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 77%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .descriptionColumn {
    width: 100%;
    margin: 0;
  }
}

.descriptionContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

@media (max-width: 991px) {
  .descriptionContent {
    margin-top: 40px;
  }
}

.description {
  font-family: Lato, sans-serif;
  line-height: 28px;
  max-width: 380px;
}

.legalLinks {
  font-family: Lato, sans-serif;
  line-height: 175%;
  letter-spacing: -0.32px;
  text-decoration-line: underline;
  margin-top: 23px;
}

.linksSection {
  flex-grow: 1;
  flex-basis: auto;
}

@media (max-width: 991px) {
  .linksSection {
    max-width: 100%;
  }
}

.linksContent {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .linksContent {
    align-items: stretch;
    gap: 0;
  }
}

.linkColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .linkColumn {
    width: 100%;
  }
}

.linkList {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: -0.32px;
}

@media (max-width: 991px) {
  .linkList {
    margin-top: 40px;
  }
}

.link {
  font-family: Lato, sans-serif;
  text-decoration-line: underline;
  margin-top: 14px;
  color: white
}

.link:first-child {
  margin-top: 0;
}

.contactColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 44%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .contactColumn {
    width: 100%;
  }
}

.contactInfo {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 117%;
}

@media (max-width: 991px) {
  .contactInfo {
    margin-top: 40px;
  }
}

.address {
  font-family: Lato, sans-serif;
  line-height: 24px;
  text-transform: capitalize;
}

.hours {
  font-family: Lato, sans-serif;
  text-transform: capitalize;
  margin-top: 14px;
}

.email {
  font-family: Lato, sans-serif;
  text-decoration-line: underline;
  margin-top: 14px;
  color: #fff;
}

.bottomBar {
  align-self: center;
  display: flex;
  margin-top: 90px;
  width: 100%;
  max-width: 1457px;
  gap: 20px;
}

@media (max-width: 991px) {
  .bottomBar {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.copyright {
  margin-top: 60px;
  color: #fff;
  letter-spacing: -0.24px;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 12px/175% Lato, sans-serif;
}

.socialIcons {
  margin-top: 50px;
  display: flex;
  gap: 20px;
}

.socialIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 45px;
}
