/* ProductInfo.module.css */
.infoContainer {
    width: 50%;

  }
  
  .productName {
    font-family: 'Basic', sans-serif;
    /* font-size: 2.8rem; */
    margin-bottom: 0.5rem;
    color:#0b0b0b;

font-size: 75px;
font-style: normal;
font-weight: 400;
line-height: 94.023%; /* 70.517px */
letter-spacing: -3px;
text-transform: capitalize;
  }
  
  .price {
margin-top: 2.5rem;
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    color: rgba(18,18,18,0.3);
    margin-bottom: 1rem;
    font-size: 35px;
    /* color: #121212; */
font-style: normal;
font-weight: 400;
line-height: 130%; /* 45.5px */
  }
  
  .description {
    margin-bottom: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #0B0B0B;
/* <p> */
font-size: 16px;
font-weight: 400;
line-height: 173.023%; /* 27.684px */


    
  }
  
  .quoteButton {
    background-color: #10BF76;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    /* font-size: 1rem; */
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

text-align: center;
font-family: 'Lato', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 18px */
text-transform: capitalize;
  }
  
  .quoteButton:hover {
    background-color: #00a844;
  }