.resourceHeader {
  text-align: left;
  margin-bottom: 30px;
  max-width: 550px;
}

.container{
  max-width: 800px;

  flex-direction: column;
}

.instRoot{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  z-index: 1;
}

.resourceType {
  color: #121212;
  text-transform: capitalize;
  font: 400 14px/125% Lato, sans-serif;
  margin-bottom: 20px;
}

.resourceTitle {
  color: #121212;
  letter-spacing: -3.4px;
  text-transform: capitalize;
  font: 400 60px/94% Basic, sans-serif;
  margin-bottom: 20px;
}

.resourceDescription {
  color: #0b0b0b;
  font: 400 16px/28px Lato, sans-serif;
}

@media (max-width: 991px) {
  .resourceTitle {
    font-size: 40px;
    letter-spacing: -1.4px;
  }
}

.instRoot::before {
  content: "";
  position: absolute;
  top: 640px;  /* Adjust as needed */
  width: 150%;  /* Adjust as needed */
  height: 570px;  /* Adjust as needed */
  background-color: #121212;  /* Your desired color */
  z-index: -1;
}

.whiteText{
  color: #ffffff !important;
}
