/* InstructionalVideos.module.css */
.container {
    width: 100%;
  }
  
  
  .videoSection {
    padding: 4rem 0;
    transition: background-color 0.3s ease;
  }
  
  .darkBg {
    background-color: #000;
    color: #fff;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .textContent {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease-out forwards;
  }
  
  .partNumber {
    font-size: 0.875rem;
    color: #666;
  }
  
  .darkBg .partNumber {
    color: #999;
  }
  
  .title {
    font-size: 4rem;
    /* font-weight: bold; */
    margin: 0.5rem 0 1rem;
/* H3 */
font-family: 'Basic', sans-serif;
font-size: 85px;
font-style: normal;
font-weight: 400;
line-height: 94.023%; /* 79.919px */
letter-spacing: -3.4px;
text-transform: capitalize;
color: #121212;

  }
  
  .description {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 600px;
    /* <p> */
font-family: 'Lato', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 173.023%; /* 27.684px */
  }

  














  .videoModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .videoModalContent {
    position: relative;
    width: 80%;
    max-width: 1000px;
  }
  
  .closeButton {
    position: absolute;
    top: -40px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }


  
  
  .videoContainer {
    position: relative;
    width: 100%;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease-out 0.2s forwards;
  }
  
  .thumbnail {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .playButton:hover {
    background-color: rgba(0, 0, 0, 0.9);
    transform: translate(-50%, -50%) scale(1.1);
  }
  
  .playIcon {
    color: #fff;
    width: 24px;
    height: 24px;
  }
  
  .exploreSection {
    background-color: #000;
    color: #fff;
    padding: 2rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease-out 0.4s forwards;
  }
  
  .exploreHeading {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .exploreButton {
    background: none;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .exploreButton:hover {
    transform: translateX(5px);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .arrowIcon {
    color: #fff;
    transition: transform 0.2s;
  }
  
  .exploreButton:hover .arrowIcon {
    transform: translateX(2px);
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (min-width: 768px) {
    .content {
      flex-direction: row;
      align-items: center;
    }
  
    .textContent {
      flex: 1;
    }
  
    .videoContainer {
      flex: 1;
    }
  }