.resourceCard {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.videoPlayer {
  width: 100%;
  height: auto;
}

.resourceImageWrapper {
  border-radius: 5px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.resourceImage {
  max-width: 100%;
  height: auto;
}

.resourceTitle {
  color: #181818;
  font: 400 15px/130% Basic, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 20px;
}

.downloadButton {
  border-radius: 5px;
  background-color: #1e1e1e;
  display: flex;
  gap: 7px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  line-height: 125%;
  padding: 13px 72px;
  border: none;
  cursor: pointer;
  align-items: center;
  width: 100%;
}

.resourceInfo{
  width: 100%;
}

.downloadIcon {
  width: 17px;
  height: 17px;
}

@media (max-width: 991px) {
  .resourceCard {
    flex-direction: column;
  }

  .resourceImageWrapper {
    width: 45%;
    padding: 20px;
  }

  .downloadButton {
    width: 100%;
    justify-content: center;
  }
}
