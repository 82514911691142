.buttonContainer {
  position: absolute;
  top: 1rem;
  right: 5rem;
  z-index: 40;
}

.basic {
  font-weight:500;
  font-style: 'Basic', sans-serif !important;
  font-kerning: 5px;
}

/* .contactContainer {
  background-color: #1e1e1e;
  max-width: 510px;
  flex-direction: column;
  align-items: start;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 125%;
  padding: 44px 50px;
  margin: 0 auto;
  top: 0;
} */


.openButton {
  width: 4rem;
  height: 4rem;
  padding: 0;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
}

.buttonIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modalContent {
  font-style: 'Basic', sans-serif;
  background-color: #121212;
  color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 28rem;
  width: 100%;
  position: relative;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.closeButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.closeIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.modalTitle {
  font-style: 'Basic', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.tab {
  flex-grow: 1;
  background: none;
  border: 1px solid white;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tab:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.activeTab {
  background-color: white;
  color: #121212;
}

.phoneContainer {
  background-color: white;
  color: #121212;
  padding: 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}

.phoneNumber {
  font-style: 'Basic', sans-serif;

  text-align: center;
  font-weight: bold;
}

.form {
  display: grid;
  gap: 1rem;
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  /* color: white; */
  color: rgba(255,255,255, 0.7);
  font-size: 0.875rem;
  padding: 0.5rem 0;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.input:focus {
  outline: none;
  border-color: white;
}

.submitButton {
  font-style: 'Basic', sans-serif;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.submitButton:hover {
  opacity: 0.8;
}

.submitIcon {
  width: 4.5rem;
  height: 4.5rem;
}

/* .buttonContainer {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
}

.openButton {
  width: 4rem;
  height: 4rem;
  padding: 0;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
}

.buttonIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modalContent {
  background-color: #121212;
  color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 28rem;
  width: 100%;
  position: relative;
}

.closeButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.closeIcon {
  height: 1.5rem;
  width: 1.5rem;
}

.modalTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.tab {
  flex-grow: 1;
  background: none;
  border: 1px solid white;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tab:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.activeTab {
  background-color: white;
  color: #121212;
}

.phoneContainer {
  background-color: white;
  color: #121212;
  padding: 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}

.phoneNumber {
  text-align: center;
  font-weight: bold;
}

.form {
  display: grid;
  gap: 1rem;
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  font-size: 0.875rem;
  padding: 0.5rem 0;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.input:focus {
  outline: none;
  border-color: white;
}

.submitButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.submitButton:hover {
  opacity: 0.8;
}

.submitIcon {
  width: 1.25rem;
  height: 1.25rem;
} */