/* AdditionalInfo.module.css */
.additionalInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    gap: 3rem;
    /* width:80%; */
    padding-left: 5.5rem;
    padding-right:5rem;
    padding-top: 5rem;
    padding-bottom:5rem;
  }
  
  .leftColumn {
    flex: 1;
  }
  
  .rightColumn {
    flex: 2;
  }
  
  .title {
    color: #121212;
    font-family: 'Basic', sans-serif;
    /* font-size: 2.75rem; */
    font-weight: 500;
    margin-bottom: 1rem;
    /* line-height:2.25rem; */

      /* H3 */
font-size: 85px;
font-style: normal;
font-weight: 400;
line-height: 94.023%; /* 79.919px */
letter-spacing: -3.4px;
text-transform: capitalize;
  }
  
  .shareButtons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .shareLabel {
    font-size: 0.9rem;
    color: #888;
  }
  
  .shareButton {
    background: none;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .shareButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .shareButton img {
    width: 15px;
    height: 15px;
  }
  
  .accordion {
    width: 100%;
  }
  
  .accordionItem {
    border-bottom: 1px solid #333;
  }
  
  .accordionButton {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    /* color: #121212; */
    /* font-style: 'Lato', sans-serif; */
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;

    color: #1E1E1E;

    /* H6 */
    font-family: 'Basic', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 45.5px */
  }
  
  .accordionButton:hover {
    color: #888;
  }
  
  .accordionButton.active {
    color: #000;
  }
  
  .icon {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .accordionContent {
    padding: 1rem 0;
    /* color: #888;
    line-height: 1.6; */


    color: #0B0B0B;

    font-family: 'Lato', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 173.023%; /* 27.684px */
  }
  
  @media (max-width: 768px) {
    .additionalInfo {
      flex-direction: column;
    }
  
    .leftColumn, .rightColumn {
      flex: 1;
    }
  }