/**
 * This code was generated by Builder.io.
 */
.teamSection {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.sectionTitle {
  color: #000;
  text-align: right;
  letter-spacing: -4.6px;
  text-transform: capitalize;
  align-self: flex-end;
  font: 400 115px/94% Basic, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .sectionTitle {
    max-width: 100%;
    font-size: 40px;
  }
}

.divider {
  margin-top: 41px;
  height: 1px;
  border: 1px solid rgba(100, 100, 100, 1);
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
    margin-top: 40px;
  }
}

.teamGrid {
  margin-top: 68px;
  width: 100%;
}

@media (max-width: 991px) {
  .teamGrid {
    max-width: 100%;
    margin-top: 40px;
  }
}

.teamColumns {
  display: flex;
  flex-wrap: wrap;
  max-width: 1170px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .teamColumns {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.teamColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
}

@media (max-width: 991px) {
  .teamColumn {
    width: 100%;
  }
}

.memberCard {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 400;
  width: 350px;
  overflow: hidden;
  margin-left: 40px;
}

@media (max-width: 991px) {
  .memberCard {
    max-width: 100%;
    margin-top: 25px;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
}

.memberCard:nth-child(3n+2) {
    margin-top: 40px;
}

@media (max-width: 1264px) {
  .memberCard:nth-child(3n+2) {
    margin-top: 0;
  }
  .teamColumns{
    justify-content: center;
    gap: 30px;
    align-items: center;
  }
  .memberCard{
    margin-left: 0;
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  .teamColumns{
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .imageWrapper {
    max-width: 100%;
  }
}

.memberImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.memberInfo {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  align-items: flex-end;
}

.memberDetails {
  margin-right: 15px;
}

.memberName {
  font: 35px/130% Basic, sans-serif;
}

.memberPosition {
  letter-spacing: 2.8px;
  text-transform: uppercase;
  color: #fff;
  margin-top: 3px;
  font: 800 14px Lato, sans-serif;
}

.memberDescription {
  margin-top: 15px;
  font: 13px/23px Lato, sans-serif;
  height:5em;
  overflow:hidden;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 22px;
  cursor: pointer;
}

.additionalImage {
  aspect-ratio: 0.84;
  object-fit: contain;
  width: 100%;
  margin-top: 33px;
}

@media (max-width: 991px) {
  .additionalImage {
    max-width: 100%;
  }
}

.middleColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .middleColumn {
    width: 100%;
  }
}

.middleColumnContent {
  display: flex;
  margin-top: 106px;
  flex-direction: column;
}

@media (max-width: 991px) {
  .middleColumnContent {
    max-width: 100%;
    margin-top: 40px;
  }
}

.middleImage {
  aspect-ratio: 0.85;
  object-fit: contain;
  width: 100%;
}

@media (max-width: 991px) {
  .middleImage {
    max-width: 100%;
  }
}

.middleImageSecond {
  aspect-ratio: 0.85;
  object-fit: contain;
  width: 100%;
  margin-top: 35px;
}

@media (max-width: 991px) {
  .middleImageSecond {
    max-width: 100%;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
