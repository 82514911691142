.resourceCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}





/* .resourceCard {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
} */

.resourceImageWrapper {
  border-radius: 5px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.resourceImage {
  max-width: 100%;
  height: auto;
}

.resourceTitle {
  color: #181818;
  font: 400 25px/130% Basic, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .resourceTitle {
  color: #181818;
  font: 400 25px/130% Basic, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 20px;
} */

.downloadButton {
  border-radius: 5px;
  background-color: #1e1e1e;
  display: flex;
  gap: 7px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  line-height: 125%;
  padding: 13px 72px;
  border: none;
  cursor: pointer;
  align-items: center;
}

.downloadButton2 {
  border-radius: 5px;
  /* background-color: #f3f3f3; */
  display: flex;
  gap: 7px;
  font-size: 16px;
  color: #2f2f2f;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  line-height: 125%;
  padding: 13px 72px;
  border: none;
  cursor: pointer;
  align-items: center;
}

.downloadIcon {
  width: 17px;
  height: 17px;
}

@media (max-width: 991px) {
  .resourceCard {
    flex-direction: column;
  }

  .resourceImageWrapper {
    width: 100%;
    padding: 20px;
  }

  .downloadButton {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .resourceCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .resourceImageWrapper {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }

  .downloadButton,
  .downloadButton2 {
    width: 100%;
    justify-content: center;
  }
}