.callButtonContainer {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 50;
}

.callButton {
  padding: 0;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: none;
}

.pngIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #121212;
  color: white;
  padding: 24px;
  border-radius: 8px;
  max-width: 425px;
  width: 100%;
  z-index: 100;
}

.modalTitle {
  font-style: 'Basic', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.tabContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab {
  font-style: 'Basic', sans-serif;

  flex-grow: 1;
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab:not(:last-child) {
  border-right: none;
}

.tab:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tab:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.activeTab {
  background-color: #ffffff;
  color: #121212;
}

.phoneContainer {
  font-style: 'Basic', sans-serif;

  background-color: #ffffff;
  color: #121212;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.phoneNumber {
  text-align: center;
  font-weight: bold;
  font-style: 'Basic', sans-serif;

}

.form {
  display: grid;
  gap: 16px;
  font-style: 'Basic', sans-serif;

}

.formGroup {
  display: grid;
  gap: 8px;
}

.input {
  font-style: 'Lato', sans-serif;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 0;
  width: 100%;
}

.input::placeholder {
  color: #ffffff;
  opacity: 0.7;
}

.input:focus {
  outline: none;
  border-color: #ffffff;
}

.submitButton {
  font-style: 'Basic', sans-serif;
  background-color: transparent;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 20px;
}

.submitButton:hover {
  opacity: 0.8;
}

.submitIcon {
  margin-left: 8px;
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 8px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.closeIcon {
  width: 20px;
  height: 20px;
}

@media (max-width: 480px) {
  .modal {
    width: 90%;
    padding: 20px;
  }

  .modalTitle {
    font-size: 20px;
  }

  .input {
    font-size: 12px;
  }

  .submitButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}