

.flex {
  display: flex;
}



.mr-2 {
  margin-right: 4rem;
}


.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(18, 18, 18, 1);
  padding: 1rem 2rem;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 0rem;
}
  /* Add this new class */
.navItems {
  margin-right: .55rem; /* This will push both the call button and nav dropdown icon to the left by 2rem */
}



  /* Add these new classes */
.menuToggle {
  margin-right: 1rem; /* This creates a 1rem gap between menu toggle and call button */
  display: flex;
  align-items: center;
}


.callButton {
  display: flex;
  align-items: center;
}




/* .fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(18, 18, 18, 1);
  padding: 1rem 2rem;
} */


/* .navItems {
  margin-right: 2rem;
} */

/* .menuToggle {
  margin-right: 1rem;
} */

/* .callButton {
  display: flex;
  align-items: center;
} */




/* Adjust other classes as needed */
.menuContent {
  position: fixed;
  top: 80px; /* Adjust this value based on your header height */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(18, 18, 18, 1);
  overflow-y: auto;
  z-index: 999;
  pointer-events: none; /* This allows clicking through the overlay */
  display: flex; /* Added to enable flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.menuInnerContent {
  padding-top: 2rem; /* Reduced padding-top */
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
}





.w-70px {
  width: 70px;
  height: auto;
}

.w-89px {
  width: 89px;
  height: auto;
}








/* added classes */




/* .callButton {
  display: flex;
  align-items: center;
} */

/* Modify the existing .headerContainer class */
.headerContainer {
  position: fixed;
  top: 2.5rem;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease;
}



/* Add a new class for the menu's inner content */
.menuInnerContent {
  pointer-events: auto; /* This allows interaction with the menu content */
  padding-top: 4rem; /* Added to push content down */
  max-width: 90%; /* Limit the width of the content */
  width: 100%;
}








/* Add these new classes */
.menuToggle {
  margin-right: 2rem; /* This creates the 2rem gap between menu toggle and call button */
}



/* .headerContainer {
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease;
} */

.logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.companyName {
  font-size: 1.5rem;
  font-weight: 700;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}








/* Add or modify these classes in your nav.module.css file */

.headerContainer {
  position: fixed;
  top: 2.5rem;
  left: 0rem;
  right: 1rem;
  z-index: 1000;
  background-color: #121212;
  transition: background-color 0.3s ease;
}

.nav-open {
  height: 100vh;
  overflow: hidden;
}


.basic {
  font-weight:500;
  font-style: 'Basic', sans-serif !important;
  font-kerning: 5px;
}

.menuContent {
  position: fixed;
  top: 100px; /* Adjust this value based on your header height */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(18, 18, 18, 0.95); /* Adjust the color and opacity as needed */
  overflow-y: auto;
  z-index: 999;
}

/* Adjust the existing .py-16 class */
.py-16 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Add a new class for the menu's inner content */
.menuInnerContent {
  padding-top: 4rem; /* Adjust as needed */
  padding-left: 4rem;
  padding-right:4rem;
}



.iconButton:hover {
  opacity: 0.8;
}

.gap4 {
  gap: 1rem;
}

.textWhite {
  color: white;
}

.textXl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.fontBold {
  font-weight: 700;
}





.gap-5 {
  gap: 1.25rem; /* 5 * 0.25rem */
}

.gap-7 {
  gap: 2.25rem; /* 5 * 0.25rem */
}

/* .nav-open {
  height: 100vh;
  overflow: hidden; /* Prevent scrolling */
  /* margin-top:-16vh; 

} */

.pointer{
  cursor: pointer;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: flex-center;
}

.w-full {
  width: 100%;
}
.link-hover:hover{
  text-decoration: underline;
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.align-end{
  align-items: flex-end;
}

.text-decoration-none {
  text-decoration: none;
}

.font-bold {
  font-weight: 700;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #ffffff;
}

.whitespace-nowrap {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .max-md\\:flex-wrap {
    flex-wrap: wrap;
  }

  .max-md\\:max-w-full {
    max-width: 100%;
  }
}

.shrink-0 {
  flex-shrink: 0;
}

.aspect-0-99 {
  aspect-ratio: 0.99;
}

.w-69px {
  width: 69px;
}

.flex-auto {
  flex: 1 1 auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-2-5 {
  margin-top: 0.625rem; /* 2.5 * 0.25rem */
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.w-89px {
  width: 89px;
}

.w-70px {
  width: 70px;
}

.flex-col {
  flex-direction: column;
}

.self-start {
  align-self: flex-start;
}

.text-9xl {
  font-size: 4rem; /* 96px */
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.capitalize {
  text-transform: capitalize;
}

.leading-117 {
  line-height: 70px;
}

.text-white {
  color: #ffffff;
}

.text-opacity-50 {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .max-md\\:max-w-full {
    max-width: 100%;
  }

  .max-md\\:text-4xl {
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
  }
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.gap-5 {
  gap: 1.25rem; /* 20px */
}

.justify-between {
  justify-content: space-between;
}

.mt-36 {
  margin-top: 6rem; /* 144px */
}

.shrink-0 {
  flex-shrink: 0;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.w-45px {
  width: 45px;
}

@media (max-width: 768px) {
  .max-md\\:mt-10 {
    margin-top: 2.5rem; /* 40px */
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.bg-white {
  background-color: white;
}

.bg-neutral-900 {
  background-color: #121212; /* Example color for neutral-900 */
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

/* .py-16 {
  padding-top: .5rem;
  padding-bottom: 4rem;
} */

.w-full {
  width: 100%;
}

.max-md\\:px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.max-md\\:max-w-full {
  max-width: 100%;
}

.mb-16 {
  margin-bottom: 4rem;
}

.max-w-1606px {
  max-width: 1606px;
}

.max-md\\:mb-10 {
  margin-bottom: 2.5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.items-start {
  align-items: flex-start;
}

.max-md\\:flex-wrap {
  flex-wrap: wrap;
}

.max-md\\:mt-10 {
  margin-top: 2.5rem;
}

.flex-auto {
  flex: 1 1 auto;
}

.self-end {
  align-self: flex-end;
}

.items-end{
  align-items: end;
}
.mt-96 {
  margin-top: 24rem;
}

.mt-56{
  margin-top: 9rem;
}

.bg-transparent {
  background-color: transparent !important;
}

.max-md\\:ml-0 {
  margin-left: 0;
}

.max-md\\:w-full {
  width: 100%;
}

.text-2xl {
  font-size: 1.5rem;
}

.capitalize {
  text-transform: capitalize;
}

.leading-79px {
  line-height: 79px;
}

.text-white {
  color: white;
}

.text-opacity-50 {
  opacity: 0.5;
}

.max-md\\:mt-10 {
  margin-top: 2.5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.w-41 {
  width: 41%;
}

.w-59 {
  width: 59%;
}

.max-md\\:ml-0 {
  margin-left: 0;
}

.max-md\\:w-full {
  width: 100%;
}

.call-icon{
  margin-right: -75px;
}
