.faqAccordion {
    margin-top: 10px;
    width: 100%;
  }
  
  .faqTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "Basic", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 0;
    transition: color 0.3s ease;
  }
  
  .faqTitle img {
    margin-right: 10px;
    transition: transform 0.3s ease;
    height: 25px;
    width: 25px;
  }
  
 
  .faqTitle.active img {
    transform: rotate(45deg);
  }
  
  .faqContent {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(-10px);
    font-family: "Lato", Helvetica;
    font-size: 15px;
    font-weight: 400;
    margin-left: 35px;
  }
  
  .faqContent.active {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0);
    padding-bottom: 15px;
  }
  
  .faqDivider {
    height: 1px;
    margin: 10px 0;
    transition: background-color 0.3s ease;
  }