.flex {
  display: flex;
}

.root {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1 !important;
  width: 100%;
  transition: background-color 0.3s ease, z-index 0.3s ease;
  overflow: hidden;
  margin-top: -35px; /* Add this line */

}

/* .menuOpenClass{
  background-color: #121212;
  flex-direction: column;
} */

.menuOpenClass {
  background-color: #121212;
  flex-direction: column;
  z-index: 10000 !important; /* Add this line */
  /* height: 100vh; Add this line */
}

.callIconMobile{
  cursor: pointer;
  width: 89px;
  position: fixed;
  right: 0;
  bottom: 0;
}

.headerTextRoot{
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.8px;
  color: #fff;
  white-space: nowrap;
  max-width: 480px;
  max-width: auto;
}

.headerTitle{
  align-self: center;
}

.container {
  display: flex;
  max-width: 480px;
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.4px;
  justify-content: space-between;
  padding-top: 15px;

}

.logoWrapper {
  display: flex;
  justify-content: center;
  gap: 9px;
  padding: 0 20px;
}

.logoIcon {
  object-fit: contain;
  object-position: center;
  width: 38px;
}

.leftIcon {
  object-fit: contain;
  object-position: center;
  width: 45px;
  cursor: pointer;
  margin: 0 30px;
}

.brandName {
  font-family: Lato, sans-serif;
  margin: auto 0;
  font-size: 22px;
}

.additionalIcon {
  aspect-ratio: 1.67;
  object-fit: auto;
  object-position: center;
  width: 50px;
  align-self: start;
}

.gap-5 {
  gap: 1.25rem; /* 5 * 0.25rem */
}

.gap-7 {
  gap: 2.25rem; /* 5 * 0.25rem */
}

.pointer{
  cursor: pointer;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: flex-center;
}

.w-full {
  width: 100%;
}
.link-hover:hover{
  text-decoration: underline;
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.align-end{
  align-items: flex-end;
}

.text-decoration-none {
  text-decoration: none;
}

.font-bold {
  font-weight: 700;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #ffffff;
}

.whitespace-nowrap {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .max-md\\:flex-wrap {
    flex-wrap: wrap;
  }

  .max-md\\:max-w-full {
    max-width: 100%;
  }
}

.shrink-0 {
  flex-shrink: 0;
}

.aspect-0-99 {
  aspect-ratio: 0.99;
}

.w-69px {
  width: 69px;
}

.flex-auto {
  flex: 1 1 auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-2-5 {
  margin-top: 0.625rem; /* 2.5 * 0.25rem */
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.w-89px {
  width: 89px;
}

.w-70px {
  width: 70px;
}

.flex-col {
  flex-direction: column;
}

.self-start {
  align-self: flex-start;
}

.text-9xl {
  font-size: 4rem; /* 96px */
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.capitalize {
  text-transform: capitalize;
}

.leading-117 {
  line-height: 70px;
}

.text-white {
  color: #ffffff;
}

.text-opacity-50 {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .max-md\\:max-w-full {
    max-width: 100%;
  }

  .max-md\\:text-4xl {
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
  }
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.gap-5 {
  gap: 1.25rem; /* 20px */
}

.justify-between {
  justify-content: space-between;
}

.mt-36 {
  margin-top: 6rem; /* 144px */
}

.shrink-0 {
  flex-shrink: 0;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.w-45px {
  width: 45px;
}

@media (max-width: 768px) {
  .max-md\\:mt-10 {
    margin-top: 2.5rem; /* 40px */
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.bg-white {
  background-color: white;
}

.bg-neutral-900 {
  background-color: #121212; /* Example color for neutral-900 */
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.w-full {
  width: 100%;
}

.max-md\\:px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.max-md\\:max-w-full {
  max-width: 100%;
}

.mb-16 {
  margin-bottom: 4rem;
}

.max-w-1606px {
  max-width: 1606px;
}

.max-md\\:mb-10 {
  margin-bottom: 2.5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.items-start {
  align-items: flex-start;
}

.max-md\\:flex-wrap {
  flex-wrap: wrap;
}

.max-md\\:mt-10 {
  margin-top: 2.5rem;
}

.flex-auto {
  flex: 1 1 auto;
}

.self-end {
  align-self: flex-end;
}

.items-end{
  align-items: end;
}
.mt-96 {
  margin-top: 24rem;
}

.mt-56{
  margin-top: 9rem;
}

.bg-transparent {
  background-color: transparent !important;
}

.max-md\\:ml-0 {
  margin-left: 0;
}

.max-md\\:w-full {
  width: 100%;
}

.text-2xl {
  font-size: 1.5rem;
}

.capitalize {
  text-transform: capitalize;
}

.leading-79px {
  line-height: 79px;
}

.text-white {
  color: white;
}

.text-opacity-50 {
  opacity: 0.5;
}

.max-md\\:mt-10 {
  margin-top: 2.5rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.w-41 {
  width: 41%;
}

.w-59 {
  width: 59%;
}

.max-md\\:ml-0 {
  margin-left: 0;
}

.max-md\\:w-full {
  width: 100%;
}

.call-icon{
  margin-right: -75px;
}
