/* FAQs.module.css */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .resourcesHeader {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out forwards;
  }
  
  .title {
    /* font-size: 2.5rem; */
    font-weight: bold;
    margin-bottom: 2rem;
    opacity: 0;
    /* H3 */
font-family: 'Basic', sans-serif;
font-size: 85px;
font-style: normal;
font-weight: 400;
line-height: 94.023%; /* 79.919px */
letter-spacing: -3.4px;
text-transform: capitalize;
color: #121212;
    animation: fadeInUp 0.6s ease-out 0.1s forwards;
  }
  
  .faqList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .faqItem {
    border-bottom: 1px solid #e0e0e0;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out forwards;
    animation-delay: calc(0.1s * var(--animation-order, 0));
  }
  
  .faqQuestion {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    background: none;
    border: none;
    text-align: left;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease;
    font-family: 'Lato', sans-serif;
    
  }
  
  .faqQuestion:hover {
    color: #666;
  }
  
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    transition: transform 0.3s ease;
  }
  
  .icon {
    width: 18px;
    height: 18px;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .faqQuestion.open .iconWrapper {
    transform: rotate(180deg);
  }
  
  .faqAnswer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);

    font-family: 'Lato', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 173.023%; /* 27.684px */
  }
  
  .faqAnswer.open {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0);
    padding-bottom: 1rem;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 173.023%; /* 27.684px */
  }
  
  .exploreSection {
    margin-top: 2rem;
    padding: 2rem 1rem;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out forwards;
    animation-delay: 0.4s;
  }
  
  .exploreHeading {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .exploreButton {
    background: none;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .exploreButton:hover {
    transform: translateX(5px);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .arrowIcon {
    color: #fff;
    transition: transform 0.2s;
  }
  
  .exploreButton:hover .arrowIcon {
    transform: translateX(2px);
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }