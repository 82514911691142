/* ProductSOPs.module.css */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }





  

  .videoModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .videoModalContent {
    position: relative;
    width: 80%;
    max-width: 1000px;
  }



  


  /* // added in */

  .videoPlayerLink {
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 1rem;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .videoPlayerLink:hover {
    background-color: #f0f0f0;
  }


  /* // end of added  */




  .closeButton {
    position: absolute;
    top: -40px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .closeIcon {
    width: 24px;
    height: 24px;
  }
  
  .videoPlayer {
    width: 100%;
    height: auto;
    max-height: 80vh;
  }
  
  .resourcesHeader {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out forwards;
  }
  
  .title {
    /* font-size: 2.5rem; */
    font-weight: bold;
    margin-bottom: 1rem;

    color: #121212;

/* H3 */
font-family: 'Basic', sans-serif;
font-size: 85px;
font-style: normal;
font-weight: 400;
line-height: 94.023%; /* 79.919px */
letter-spacing: -3.4px;
text-transform: capitalize;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out 0.1s forwards;
  }
  
  .description {
    /* color: #666; */
    margin-bottom: 2rem;
    max-width: 800px;

    color: #0B0B0B;

/* <p> */
font-family: 'Lato', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 173.023%; /* 27.684px */
    opacity: 0;
    animation: fadeInUp 0.6s ease-out 0.2s forwards;
  }
  
  .productList {
    display: flex;
    flex-direction: column;
    
  }
  
  .productItem {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    border-bottom: 1px solid #e0e0e0;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out forwards;
    animation-delay: calc(0.1s * var(--animation-order, 0));
    color: #181818;


  }
  
  .productImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 1.5rem;
    transition: transform 0.3s ease;
  }
  
  .productItem:hover .productImage {
    transform: scale(1.05);
  }
  
  .productInfo {
    flex-grow: 1;
  }
  
  .productName {
    font-size: 1.25rem;
    font-weight: 600;
    /* H6 */
font-family: 'Basic', sans-serif;
font-size: 35px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 45.5px */
  }
  
  .downloadButton {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
    /* H6 */
font-family: 'Basic', sans-serif;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 45.5px */
  }
  
  .downloadButton:hover {
    background-color: #333;
    transform: translateY(-2px);
  }
  
  .downloadIcon {
    margin-right: 0.5rem;
    transition: transform 0.2s;
  }
  
  .downloadButton:hover .downloadIcon {
    transform: translateY(-2px);
  }
  
  .exploreSection {
    margin-top: 2rem;
    background-color: #000;
    color: #fff;
    padding: 1.5rem;
    opacity: 0;
    animation: fadeInUp 0.6s ease-out 0.4s forwards;
  }
  
  .exploreTitle {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .exploreContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .exploreHeading {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .exploreButton {
    background: none;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .exploreButton:hover {
    transform: translateX(5px);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .arrowIcon {
    color: #fff;
    transition: transform 0.2s;
  }
  
  .exploreButton:hover .arrowIcon {
    transform: translateX(2px);
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }