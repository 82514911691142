.footerRoot{
  background-color: #121212;
  width: 100%;
}

.resourceType {
  color: white;
  text-transform: capitalize;
  font: 400 14px/125% Lato, sans-serif;
  margin-bottom: 10px;
}

.resourceTitle {
  color: white;
  letter-spacing: -3.4px;
  text-transform: capitalize;
  font: 400 40px/94% Basic, sans-serif;
}
.content {
  padding: 20px 40px;
}

.resourceWrapper{
  display: flex;
  align-items: center;
  gap: 20px
}

.nextPage {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .resourceTitle {
    font: 400 30px/94% Basic, sans-serif;
    letter-spacing: -1.4px;
  }
  .content {
    padding: 20px 20px;
  }
}
