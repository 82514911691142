/* ProductPageMobile.module.css */
.container {
  background-color: #fff;
  color: #121212;
  font-family: 'Basic', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #111;
}

.menuButton {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.mobileMenu {
  background-color: #111;
  padding: 1rem;
}

.menuItem {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: 1.2rem;
}

.main {
  padding: 1rem;
}

.productSection {
  margin-bottom: 2rem;
}

.productTitle {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.productPrice {
  font-size: 1.5rem;
  color: #888;
  margin-bottom: 1rem;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 1rem;
}

.productDescription {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.quoteButton {
  background-color: #00c853;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

.additionalInfo {
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}


.shareButtons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.shareButton {
  background-color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease;
}

.shareButton:hover {
  opacity: 0.5;
}

.accordion {
  border-top: 1px solid #333;
}

.accordionItem {
  border-bottom: 1px solid #333;
}

.accordionHeader {
  padding: 1rem 0;
  cursor: pointer;
  font-weight: bold;
}

.accordionContent {
  padding: 1rem 0;
  color: #888;
}

.systemDetails {
  margin-bottom: 2rem;
}

.featureList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.featureItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.featureTitle {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.featureDescription {
  font-size: 0.9rem;
  color: #888;
  line-height: 1.6;
}

.greenhouseImage {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 2rem;
}

.footer {
  background-color: #111;
  padding: 2rem 1rem;
}

.footerContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footerLinks {
  display: flex;
  gap: 1rem;
}

.footerLink {
  color: #888;
  text-decoration: none;
  font-size: 0.9rem;
}

.contactInfo {
  font-size: 0.9rem;
  color: #888;
}

.socialLinks {
  display: flex;
  gap: 1rem;
}

.socialLink {
  background-color: #333;
  color: #fff;
  text-decoration: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}