/* Resources.module.css */
.container {
    width: 100%;
    background-color: #f5f5f5;
  }
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .cardGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .card:hover {
    background-color: #e0e0e0;
    transform: translateY(-5px);
  }
  
  .activeCard {
    background-color: #000;
    color: #fff;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    margin-bottom: 0.5rem;
  }
  
  .cardTitle {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
  
  .contentWrapper {
    width: 100%;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .contentWrapper > * {
    animation: fadeIn 0.5s ease-out;
  }