/**
 * This code was generated by Builder.io.
 */
.container {
  display: flex;
  flex-direction: column;
  background: #000;
}

.header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }
}

.contentRow{
  display: flex;
}

.titleSection {
  align-self: start;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin: 50px;
  margin-top: 300px;
}

@media (max-width: 991px) {
  .titleSection {
    max-width: 100%;
  }
}

.title {
  color: #fff;
  letter-spacing: -7px;
  text-transform: capitalize;
  font: 110px/1 Basic, sans-serif;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
    font-size: 40px;
  }
}

.description {
  color: #fff;
  letter-spacing: -0.35px;
  align-self: start;
  margin-top: 36px;
  font: 25px/40px Lato, sans-serif;
  border: 1px solid #000;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
  }
}

.exploreSection {
  align-self: end;
  display: flex;
  gap: 27px;
  overflow: hidden;
  color: #fff;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  font: 600 14px/2 Lato, sans-serif;
}

@media (max-width: 991px) {
  .exploreSection {
    margin-top: 40px;
  }
}

.exploreText {
  transform: rotate(1.6081230200044232E16rad);
  flex-grow: 1;
  margin: auto 0;
}

.exploreIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 89px;
}

.divider {
  min-height: 1px;
  margin-top: 37px;
  width: 100%;
  border: 1px solid rgba(100, 100, 100, 1);
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
    margin-top: 40px;
  }
}

.imageSection {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 904px;
  margin-top: 52px;
  width: 100%;
}

@media (max-width: 991px) {
  .imageSection {
    max-width: 100%;
    margin-top: 40px;
  }
}

.backgroundImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 904px;
  width: 100%;
  padding-left: 80px;
  gap: 40px 100px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .contentWrapper {
    max-width: 100%;
    padding-left: 20px;
  }
}

.overlayImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.logoImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 131px;
  max-width: 100%;
  margin: auto 0;
}

.contentImage {
  aspect-ratio: 0.47;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

@media (max-width: 991px) {
  .contentImage {
    max-width: 100%;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.iframeRoot {
  height: 500px;
  margin: 0 50px;
}
