/**
 * This code was generated by Builder.io.
 */
.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #121212;
}

.brand {
  color: #fff;
  text-transform: capitalize;
  width: 100%;
  font: 600 18px/125% Lato, sans-serif;
}

@media (max-width: 991px) {
  .brand {
    max-width: 100%;
  }
}

.title {
  color: #fff;
  letter-spacing: -3.4px;
  text-transform: capitalize;
  margin-top: 25px;
  width: 100%;
  font: 400 85px/94% Basic, sans-serif;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
}

.description {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 30px;
  width: 100%;
  font: 400 16px/28px Lato, sans-serif;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
    margin-top: 40px;
  }
}

.comparisonTable {
  border-radius: 5px;
  display: flex;
  margin-top: 66px;
  width: 100%;
  flex-direction: column;
  padding: 1px 0;
}

@media (max-width: 991px) {
  .comparisonTable {
    max-width: 100%;
    margin-top: 40px;
  }
}

.headerRow {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 991px) {
  .headerRow {
    flex-wrap: wrap;
  }
}

.headerCell {
  border-radius: 0;
  background-color: rgba(16, 191, 118, 1);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  padding: 25px 56px;
}

@media (max-width: 991px) {
  .headerCell {
    max-width: 100%;
    padding: 0 20px;
  }
}

.headerIcon {
  aspect-ratio: 1.14;
  object-fit: auto;
  object-position: center;
  width: 26px;
}

.headerText {
  display: flex;
  gap: 17px;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  text-transform: capitalize;
  line-height: 130%;
  margin: auto 0;
}

@media (max-width: 991px) {
  .headerText {
    white-space: initial;
  }
}

.headerLabel {
  font-family: Lato, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
}

.comparisonRow {
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1010px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 173%;
}

@media (max-width: 991px) {
  .comparisonRow {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.comparisonCell {
  font-family: Lato, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}

.comparisonCellHeader {
  font-family: Lato, sans-serif;
  background-color: rgba(16, 191, 118, 1);
  flex: 1;
  width: fit-content;
  padding: 17px 53px;
}
.comparisonCellGreen {
  font-family: Lato, sans-serif;
  flex: 1;
  width: fit-content;
  padding: 17px 53px;
  border-left: 2px solid rgba(100, 100, 100, 0.2);
  border-right: 2px solid rgba(100, 100, 100, 0.2);
  border-bottom: 2px solid rgba(100, 100, 100, 0.2);
}

@media (max-width: 991px) {
  .comparisonCellGreen {
    max-width: 100%;
    padding: 0 20px;
  }
}

.comparisonCellGrayHeader {
  font-family: Lato, sans-serif;
  flex: 1;
  width: fit-content;
  padding: 17px 50px;
  background-color: #343434;
  border-right: 2px solid rgba(100, 100, 100, 0.2);
  border-bottom: 2px solid rgba(100, 100, 100, 0.2);
}

.comparisonCellGray {
  font-family: Lato, sans-serif;
  flex: 1;
  width: fit-content;
  padding: 17px 50px;
  border-right: 2px solid rgba(100, 100, 100, 0.2);
  border-bottom: 2px solid rgba(100, 100, 100, 0.2);
}

@media (max-width: 991px) {
  .comparisonCellGray {
    max-width: 100%;
    padding: 0 20px;
  }
}

.brandSection{
  max-width: 1000px;
  margin: 0 auto;
}

.bgLightGreen{
  background-color: rgb(20,28,24);
}
.bgLightGrey{
  background-color: #343434;
}
.bgLightBlack{
  background-color: #121212;
}
