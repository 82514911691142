.form {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .submitButton {
    height: 89px;
    width: 179px;
    position: relative;
    cursor: pointer;
    align-self: flex-end; /* Add this line */
    margin-right: 20px; /* Add some right margin if needed */
  }
  
  
  
  .submitText {
    color: #ffffff;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2.8px;
    line-height: 22.1px;
    position: absolute;
    left: 0;
    top: 36px;
    white-space: nowrap;
  }
  
  .input {
    background: transparent;
    border: none;
    color: #ffffff;
    font-family: "Basic", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.5px;
    padding: 0;
    width: 100%;
    background-color: inherit;
    margin-bottom: 30px;
  }
  
  .input:hover,
  .input:focus {
    text-decoration: none !important;
    outline: none;
  }
  
  .select {
    composes: input;
    appearance: none;
    background-image: url('/img/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
  }
  
  .textarea {
    composes: input;
    resize: vertical;
    min-height: 100px;
  }
  
  .line {
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 30px;
  }

  .submitArrow {
    margin-left:90px;
        width: 89px;
        height: 89px;
        background-image: url('/icon_dark.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: all 0.3s ease;
      }
      
      /* .submitButton:hover .submitArrow {
      background-image: url('/icon_dark.svg'); 
      }
       */



       .inputWrapper {
        position: relative;
        width: 100%;
      }
      
      .error {
        border-color: #ff4444;
      }
      
      .errorText {
        color: #ff4444;
        font-size: 12px;
        position: absolute;
        bottom: -20px;
        left: 0;
        z-index: 1;
      }