.resourceCardsContainer {
  width: 100%;
}

.resourceCardsGrid {
  display: flex;
  gap: 20px;
}

@media (max-width: 991px) {
  .resourceCardsGrid {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.resourceCardWrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  height: 160px;
  width: 33.3333%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .resourceCardWrapper {
    width: 80%;
  }
}

.resourceCard {
  border-radius: 5px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  font-size: 24px;
  color: #b2b2b2;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 125%;
  padding: 20px;
  border: 2px solid #b2b2b2;
  cursor: pointer;
}

.activeCard{
  color: black;
  border: 2px solid black
}

.resourceIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 50px;
}

.resourceTitle {
  font-family: Lato, sans-serif;
  margin-top: 25px;
  font-size: 1.3rem;
}

@media (max-width: 991px) {
  .resourceCard {
    margin-top: 30px;
    padding: 20px;
  }
}

.divider {
  background-color: #646464;
  height: 1px;
  border: none;
  margin: 70px 0;
  width: 100%;
}
